*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  font-family: Arial, Helvetica, sans-serif;
    color: #ffff;
    background: #1d2023;
    background-attachment: fixed;
  overflow-x: hidden;
  justify-content: center;
  align-items: center;
  text-align: left;

}

.row {
  width: 100%;
  height: 8px;
  background-color: #d55e2a;
  border-radius: 10px;
  margin-top: 24px;
  justify-content: center;
  align-items: center;

}

.row1 {
  width: 100%;
  height: 8px;
  background-color: #d55e2a;
  border-radius: 10px;
  margin-top: 24px;
  justify-content: center;
  align-items: center;

}


h1 {
  font-size: 3rem;
}

h2 {
  color: #fff;
}
a {
  color: indianred;
  
}

.heading {
  margin-top: 0;
}

.page {
  margin: 0 auto;
  padding: 240px 24px;
  max-width: 960px;
}

.pageAbout {
  backdrop-filter: blur(5px) !important;
  margin: 0 auto;
  padding: 100px 24px;
  max-width: 960px;
}
#tsparticles {
  position: fixed !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  width: 100%;
  height: 100%;
}

.post-preview__img2 {
  max-width: 100%;
  width: 50%;
  height: 300px;
  object-fit: contain;
  float: right;
}

.pageAbout1 {
  margin: 0 auto;
  padding: 48px 24px;
  max-width: 960px;
  float: left
}
.profileimg {
  max-width: 100%;
  width: 50%;
  height: 300px;
  object-fit: contain;
  float: right;
}
.page1 {
  
    margin: 0 auto;
    padding: 380px 24px;
    max-width: 960px;
  }
  

.blog-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  grid-gap: 36px;

}

.post-preview {
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px) !important;

  border-radius: 8px;
  overflow: hidden;
  border-radius: 12px;

}

.post-preview__img {
  max-width: 100%;
  width: 100%;
  height: 320px;
  object-fit: cover;
}

.post-preview__img1 {
  max-width: 100%;
  width: 50%;
  height: 300px;
  object-fit: contain;
}




.post-preview__text {
  padding: 16px;
}

.link-wrapper {
  margin-bottom: 32px;
}

.link-wrapper a {
  font-weight: bold;
}

.post {
  backdrop-filter: blur(15px) !important;
  margin-top: 24px;
  padding: 24px;
  border-radius: 8px;
}

.post__img {
  max-width: 100%;
  width: 100%;
  height: 480px;
  object-fit: contain;
  margin-bottom: 32px;
}

/* --------- */
/*Scrollbar   */
/* --------- */

::-webkit-scrollbar {
    width: 7px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #fff;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #d55e2a;
    border-radius: 12px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #d55e2a(222, 130, 235, 0.911);
    border-radius: 12px;
  }
  /* --------- */
/* Navbar Section  */
/* --------- */
.sticky {
  background-color: rgba(0, 0, 0, 0.5) !important;
  transition: all 0.3s ease-out 0s !important;
  box-shadow: 0px 10px 10px 0px rgba(9, 5, 29, 0.171) !important;
  backdrop-filter: blur(15px) !important;
}

.navbar {
  position: fixed !important;
  transition: all 0.3s ease-out 0s !important;
  padding: 0.3rem 2rem !important;
  font-size: 1.2rem !important;
}

.navbar-toggler {
  padding: 0.25rem 1.5rem !important;
  position: relative !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.navbar-toggler span {
  display: block !important;
  background-color: #d55e2a !important;
  height: 4px !important;
  width: 27px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  transform: rotate(0deg) !important;
  left: 0 !important;
  opacity: 1 !important;
}

.navbar-toggler:focus,
.navbar-toggler:active {
  outline: 0 !important;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
  transition: transform 0.35s ease-in-out !important;
  transition: transform 0.35s ease-in-out !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(135deg) !important;
  opacity: 0.9 !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  height: 12px !important;
  visibility: hidden !important;
  background-color: transparent !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(-135deg) !important;
  opacity: 0.9 !important;
}

@media (max-width: 767px) {
  .navbar {
    padding: 1rem 2rem !important;
    font-size: 1.4rem !important;
    background-color: rgbargba(0, 0, 0, 0.5) !important;
  }
  .navbar-nav .nav-item a::after {
    display: none !important;
  }
}
.navbar-brand {
  color: rgb(250, 250, 250) !important;
}

.logo {
  height: 3.5rem !important;
  width: 5rem !important;
}


.navbar-nav .nav-link {
  color: white !important;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.nav-link {
  padding: 0.8rem 1rem !important;
}

@media (max-width: 767px) {
  .nav-link {
    padding: 0.7rem 1rem !important;
  }
}

.navbar-nav .nav-item {
  position: relative;
  margin-left: 20px;
  margin-top: 10px
}

.navbar-nav .nav-item a {
  font-weight: 400;
  transition: all 0.3s ease-out 0s;
  position: relative;
  z-index: 1;
  cursor: pointer;
  margin-top: -5px

  
}

.navbar-nav .nav-item a::after {
  content: "";
  position: relative;
  display: block;
  height: 5px;
  width: 0;
  border-radius: 16px;
  background: #d55e2a;
  bottom: 1px;
  left: 0;
  z-index: -1;
  transition: all 0.3s ease-out 0s;
}

.navbar-nav .nav-item a:hover::after {
  width: 100%;
}

  
/* --------- */
/* Footer */
/* --------- */
.footer {
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0 !important;
  padding-top: 10px !important;
  padding-bottom: 18px !important ;
  backdrop-filter: blur(15px) !important;
}
.footer-copywright {
  text-align: center !important;
  
}

.footer-body {
  text-align: center !important;
}

@media (max-width: 767px) {
  .footer-copywright {
    text-align: center !important;
  }

  .footer-body {
    text-align: center !important;
  }
}

.footer h3 {
  font-size: 1em;
  color: white !important;
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
}


.footer-icons {
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
  padding: 0 !important;
  
}

